import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import "../utils/i18n";
import axiosInstance from '../utils/axiosInstance';
import Country from '../components/Country2';
import Loading from '../components/Loading';
import { message } from 'antd';

const Request = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '+381', // Default Serbia phone code
    company: '',
    country: '',
    city: '',
    message: '',
    productType: 'KU-FC'
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axiosInstance.post('/forms/request', formData);
      
      if (response.data.success) {
        message.success(t('form_success'));
        setFormData({
          name: '',
          email: '',
          phone: '+381',
          company: '',
          country: '',
          city: '',
          message: '',
          productType: 'KU-FC'
        });
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      message.error(error.response?.data?.message || t('form_error'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    if (e && e.target) {
      setFormData(prev => ({
        ...prev,
        [e.target.name]: e.target.value
      }));
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-2xl w-full space-y-8 bg-white p-8 rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold text-center mb-8">{t("Request Form")}</h1>
        
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="border rounded-lg p-3 w-full focus:outline-none focus:ring-2 focus:ring-kintecs focus:border-transparent"
              placeholder={t("Name & Surname")}
              required
            />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="border rounded-lg p-3 w-full focus:outline-none focus:ring-2 focus:ring-kintecs focus:border-transparent"
              placeholder={t("Email Address")}
              required
            />
            <Country 
              onChange={handleChange}
              value={formData.phone}
              defaultCountry="RS" // Serbia country code
            />
            <input
              type="text"
              name="company"
              value={formData.company}
              onChange={handleChange}
              className="border rounded-lg p-3 w-full focus:outline-none focus:ring-2 focus:ring-kintecs focus:border-transparent"
              placeholder={t("Company Name")}
            />
            <input
              type="text"
              name="country"
              value={formData.country}
              onChange={handleChange}
              className="border rounded-lg p-3 w-full focus:outline-none focus:ring-2 focus:ring-kintecs focus:border-transparent"
              placeholder={t("Country")}
            />
            <input
              type="text"
              name="city"
              value={formData.city}
              onChange={handleChange}
              className="border rounded-lg p-3 w-full focus:outline-none focus:ring-2 focus:ring-kintecs focus:border-transparent"
              placeholder={t("City")}
            />
            <select
              name="productType"
              value={formData.productType}
              onChange={handleChange}
              className="border rounded-lg p-3 w-full focus:outline-none focus:ring-2 focus:ring-kintecs focus:border-transparent"
              required
            >
              <option value="KU-FC">KU-FC (Fixed Unit)</option>
              <option value="KU-FB">KU-FB (Portable Unit)</option>
            </select>
          </div>
          
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            className="w-full border rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-kintecs focus:border-transparent"
            placeholder={t("Your Message")}
            rows="4"
            required
          ></textarea>
          
          <button
            type="submit"
            className="w-full p-4 bg-kintecs text-white font-bold rounded-lg hover:bg-opacity-90 transition-all duration-200 transform hover:scale-[1.02]"
          >
            {t("Submit")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Request;