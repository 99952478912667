import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import "../utils/i18n";
import axiosInstance from '../utils/axiosInstance';

const Products = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language || 'en';

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);
        setError(null);
        console.log('Fetching products...');
        const response = await axiosInstance.get('/api/products');
        console.log('Products response:', response.data);
        
        if (response.data.success) {
          setProducts(response.data.products);
        } else {
          setError('Failed to fetch products');
        }
      } catch (error) {
        console.error("Error fetching products:", error);
        setError(error.message || 'Failed to fetch products');
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!products || products.length === 0) {
    return <div>No products found</div>;
  }

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-12 lg:gap-x-20 container mx-auto py-12 pl-4 pr-4 md:pr-0 md:pl-0">
      {products.map((product) => (
        <div key={product._id} className="">
          <img 
            className="rounded-t-xl w-full h-[300px] object-cover" 
            src={`${axiosInstance.defaults.baseURL}${product.productImage}`} 
            alt={product.name[currentLang]} 
          />
          <h1 className="font-oswald text-4xl text-kintecs font-bold mt-4">
            {product.name[currentLang]}
          </h1>
          <div className="flex mt-3 gap-x-4">
            <div className="w-7/12">
              <p className="text-base text-gray-700">
                {product.description[currentLang]}
              </p>
              {/* <Link to={`/products/${product.productType.toLowerCase()}`}>
                <button className="text-white bg-kintecs px-6 py-3 font-oswald font-bold text-lg mt-4 rounded hover:bg-gray-500">
                  {t("show")}
                </button>
              </Link> */}
            </div>
            <div className="w-5/12">
              <img 
                className="w-full h-auto rounded" 
                src={`${axiosInstance.defaults.baseURL}${product.technicalImage}`} 
                alt={`${product.name[currentLang]} Technical`} 
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default Products