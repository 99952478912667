import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import axiosInstance from '../utils/axiosInstance';

const Catalogues_Details = () => {
  const { slug } = useParams();
  const [catalogue, setCatalogue] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language || 'en';

  useEffect(() => {
    const fetchCatalogue = async () => {
      try {
        setLoading(true);
        setError(null);
        const response = await axiosInstance.get(`/api/catalogues/${slug}`);
        if (response.data.success) {
          setCatalogue(response.data.catalogue);
        } else {
          setError('Failed to fetch catalogue');
        }
      } catch (error) {
        console.error("Error fetching catalogue:", error);
        setError(error.message || 'Failed to fetch catalogue');
      } finally {
        setLoading(false);
      }
    };

    fetchCatalogue();
  }, [slug]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!catalogue) {
    return <div>Catalogue not found!</div>;
  }

  return (
    <div className="mx-auto container">
      <div className="flex justify-between items-center mt-12">
        <h1 className="text-kintecs font-oswald font-bold text-5xl">{catalogue.title[currentLang]}</h1>
        <div className="h-full items-center">
          <a target='_blank' href={`${axiosInstance.defaults.baseURL}${catalogue.download}`} rel="noopener noreferrer">
            <button className="bg-kintecs py-2 px-4 text-white font-oswald font-bold h-full">
              View/Download Form
            </button>
          </a>
        </div>
      </div>

      <iframe
        src={`${axiosInstance.defaults.baseURL}${catalogue.download}`}
        width="100%"
        height="600px"
        className="border rounded-lg mt-12 mb-12"
        title={catalogue.title[currentLang]}
      ></iframe>
    </div>
  )
}

export default Catalogues_Details