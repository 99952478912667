import React from 'react';
import { useTranslation } from "react-i18next";
import "../utils/i18n";
import Subscribe from '../components/Subscribe';

const Kufb = () => {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-5xl font-oswald font-bold text-kintecs mb-8">
        {t("Fixed Unit (KU-FB)")}
      </h1>

      <div className="mb-12">
        <img 
          src="/assets/products/ku-fb-1.jpg" 
          alt="KU-FB Product" 
          className="w-full h-[500px] object-cover rounded-lg shadow-lg mb-8"
        />
        
        <h2 className="text-2xl font-oswald font-bold text-kintecs mb-4">
          {t("Fixed to the balcony")}
        </h2>
        
        <div className="prose max-w-none text-gray-700">
          <p className="mb-6">
            {t("kufb_description_1")}
          </p>
          <p className="mb-6">
            {t("kufb_description_2")}
          </p>
          <p className="mb-6">
            {t("kufb_description_3")}
          </p>
        </div>
      </div>

      <div className="bg-gray-100 p-8 rounded-lg mb-12">
        <h2 className="text-2xl font-oswald font-bold text-kintecs mb-6">
          {t("Technical Details")}
        </h2>
        <img 
          src="/assets/products/ku-fb-technical.jpg" 
          alt="KU-FB Technical Details" 
          className="w-full h-auto rounded-lg shadow-lg"
        />
      </div>

      <div className="bg-gray-100 p-8 rounded-lg mb-12">
        <h2 className="text-2xl font-oswald font-bold text-kintecs mb-6">
          {t("DOCUMENTS")}
        </h2>
        <p className="text-gray-700 mb-4">
          {t("Test Reports, Certificates, BIM Objects, Drawings, Documents, Forms")}
        </p>
        {/* Buraya döküman linkleri eklenebilir */}
      </div>

      <Subscribe />
    </div>
  );
};

export default Kufb;