import React, { useState, useEffect } from 'react';
import { MdDeleteForever, MdDeleteSweep, MdOutlineCancel } from "react-icons/md";
import ToggleButton from '../components/ToggleButton';
import axiosInstance from '../utils/axiosInstance';
import { useNavigate } from 'react-router-dom';

const AddProjects = () => {

const [projects, setProjects] = useState([]);
const navigate = useNavigate();

const getAllProjects = async() => {
    try{
        const response = await axiosInstance.get("/api/projects");
        setProjects(response.data.projects);
    } catch (error) {
        console.error("Error fetching projects:", error);
    }
};

useEffect(() => {
    getAllProjects();
  }, []);

return (
  <div className="p-8 bg-gray-100 min-h-screen ml-[218px]">
    <div className="mt-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      <div className="bg-white p-6 rounded-lg md:col-span-3 container mx-auto">
        <div className="flex justify-between items-start">
          <div>
            <h2 className="text-lg font-bold">Projects</h2>
            <h1>You can add or remove all projects from here.</h1>
          </div>
          <button 
           onClick={() => navigate("/admin/edit-project")}
            className="bg-libart text-white hover:bg-cyan-900 px-6 py-3 rounded-full font-semibold"
          >
            Add Project
          </button>
        </div>

        <div className="container mx-auto pt-6">
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-200 rounded-lg">
              <thead className="bg-libart text-white">
                <tr>
                  <th className="py-3 px-4 text-left"></th>
                  <th className="py-3 px-4 text-left">Title</th>
                  <th className="py-3 px-4 text-left">Number</th>
                  <th className="py-3 px-4 text-left">Active</th>
                  <th className="py-3 px-4 text-left"><MdDeleteSweep size={26} /></th>
                </tr>
              </thead>
              <tbody>
  {projects.map((project) => (
    <tr
      key={project._id}
      className="border-b border-gray-200 hover:bg-gray-100 cursor-pointer"
      onClick={() => navigate(`/admin/edit-project/${project._id}`)} // Proje ID'sini yönlendirme URL'sine ekliyoruz
    >
      <td className="py-3 px-4">
        <input
          type="checkbox"
          className="form-checkbox h-5 w-5 text-blue-600"
        />
      </td>
      <td className="py-3 px-4 flex items-center space-x-2">
        <span>{project.title?.en || project.projectName?.en || "No Title"}</span>
        {project.title &&
          Object.keys(project.title).map((lang) => (
            <span
              key={lang}
              className="rounded-full bg-gray-300 text-black px-2 py-1 text-xs font-semibold"
            >
              {lang.toUpperCase()}
            </span>
          ))}
      </td>
      <td className="py-3 px-4">{project.projectNumber || "No Number"}</td>
      <td className="py-3 px-4">{project.active ? "Yes" : "No"}</td>
      <td className="py-3 px-4">
        <button className="text-red-500 hover:text-red-700">
          <MdDeleteForever size={26} />
        </button>
      </td>
    </tr>
  ))}
  {projects.length === 0 && (
    <tr>
      <td colSpan="5" className="text-center py-4">
        No projects found
      </td>
    </tr>
  )}
</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    
  </div>
  )
}

export default AddProjects