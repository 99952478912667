import React, { useEffect, useState } from "react";
import { FaPlay } from "react-icons/fa";
import Subscribe from "../components/Subscribe";
import { useTranslation } from "react-i18next";
import "../utils/i18n";
import axios from "axios";

const Videos = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [videos, setVideos] = useState([]); // Backend'den çekilen videolar için state
  const { t } = useTranslation();
  const baseURL = "https://api.345.com.tr"; // Backend base URL

  const openModal = (videoUrl) => {
    setCurrentVideo(videoUrl);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setCurrentVideo(null);
  };

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get(`${baseURL}/video/all`); // Backend API çağrısı
        // Videoları createdOn tarihine göre azalan sırada sırala
        const sortedVideos = response.data.videos.sort((a, b) => 
          new Date(b.createdOn) - new Date(a.createdOn)
        );
        setVideos(sortedVideos); // Sıralanmış verileri state'e kaydet
      } catch (error) {
        console.error("Error fetching videos:", error);
      }
    };

    fetchVideos();
  }, []);

  return (
    <div className="mx-auto container pr-4 pl-4 md:pr-0 md:pl-0">
      <h1 className="text-kintecs font-oswald font-bold text-5xl mt-12">{t("video")}</h1>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6 mt-12">
        {videos.map((video, index) => (
          <div className="relative" key={index}>
            <div className="w-full h-64 relative flex items-center justify-center mt-9 md:mt-0">
              <img
                className="w-full h-full object-cover rounded-lg transform transition-transform duration-500 hover:scale-105 cursor-pointer"
                src={`${baseURL}${video.thumbnail}`} // Thumbnail tam URL
                alt={video.title}
                onClick={() => openModal(video.videoUrl)} // Modal için video URL'sini aç
              />
              <div className="absolute flex items-center justify-center">
                <div className="bg-white bg-opacity-70 p-4 rounded-full">
                  <FaPlay className="text-4xl text-gray-800 pl-2" />
                </div>
              </div>
            </div>

            <h2 className="text-xl font-oswald font-bold text-libart mt-3">{video.title}</h2>
            <div className="flex justify-between">
              <h3>{video.location}</h3>
              <p className="text-gray-500">{video.projectNumber}</p>
            </div>
          </div>
        ))}
      </div>

      {isOpen && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50"
          onClick={closeModal}
        >
          <div
            className="relative bg-white p-6 sm:p-8 rounded-lg max-w-3xl w-full"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className="absolute top-2 right-2 text-white bg-libart rounded-full w-8 h-8 flex items-center justify-center font-black"
              onClick={closeModal}
            >
              X
            </button>
            <div className="w-full h-full">
              {currentVideo && (
                <iframe
                  className="w-full aspect-video"
                  src={currentVideo} // Modal içinde video oynatma"
                  allowFullScreen
                  data-mce-fragment="1"
                  title="Video"
                ></iframe>
              )}
            </div>
          </div>
        </div>
      )}

      <Subscribe />
    </div>
  );
};

export default Videos;