import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import "../utils/i18n";
import axiosInstance from '../utils/axiosInstance';
import { message } from 'antd';

const Subscribe = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    email: '',
    newsSubscription: false,
    blogSubscription: false,
    agreeToTerms: false
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.email) {
      message.error(t('Please enter your email'));
      return;
    }

    if (!formData.agreeToTerms) {
      message.error(t('Please agree to terms and conditions'));
      return;
    }

    try {
      const response = await axiosInstance.post('/forms/request', {
        email: formData.email,
        message: `New subscription request:
- News Subscription: ${formData.newsSubscription ? 'Yes' : 'No'}
- Blog Subscription: ${formData.blogSubscription ? 'Yes' : 'No'}`,
        name: 'Newsletter Subscription',
        phone: '-',
        company: '-',
        country: '-',
        city: '-',
        productType: 'Newsletter'
      });

      if (response.data.success) {
        message.success(t('Subscription successful'));
        setFormData({
          email: '',
          newsSubscription: false,
          blogSubscription: false,
          agreeToTerms: false
        });
      }
    } catch (error) {
      console.error('Subscription error:', error);
      message.error(t('Subscription failed'));
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  return (
    <div>
      <div className="flex justify-center mt-48 mb-24 p-4 md:p-0">
        <div className="max-w-[460px]">
          <h1 className="font-oswald text-xl font-bold text-kintecs">{t("subscribe")}</h1>
          <form onSubmit={handleSubmit}>
            <input 
              type="email" 
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder={t("mail")} 
              className="mt-4 w-full px-3 py-2 border border-kintecs rounded-md focus:outline-none focus:ring-2 focus:ring-gray-600" 
              required
            />
         
            <div className="flex items-center space-x-4 mb-4 mt-4">
              <label className="flex items-center space-x-2">
                <input 
                  type="checkbox" 
                  name="newsSubscription"
                  checked={formData.newsSubscription}
                  onChange={handleChange}
                  className="form-checkbox h-5 w-5 bg-gray-200 border-gray-300 rounded checked:text-white accent-kintecs" 
                />
                <span className="text-gray-700 text-sm">{t("news")}</span>
              </label>
              <label className="flex items-center space-x-2">
                <input 
                  type="checkbox" 
                  name="blogSubscription"
                  checked={formData.blogSubscription}
                  onChange={handleChange}
                  className="form-checkbox h-5 w-5 bg-gray-200 border-gray-300 rounded checked:text-white accent-kintecs" 
                />
                <span className="text-gray-700 text-sm">{t("blog")}</span>
              </label>
            </div>

            <div className="mb-4">
              <label className="flex items-start space-x-2">
                <input 
                  type="checkbox" 
                  name="agreeToTerms"
                  checked={formData.agreeToTerms}
                  onChange={handleChange}
                  className="form-checkbox h-5 w-5 bg-gray-200 border-gray-300 rounded checked:text-white accent-kintecs" 
                  required
                />
                <span className="text-gray-700 text-sm">
                  <a href="#" className="underline text-kintecs">{t("agree")}</a>
                </span>
              </label>
            </div>

            <button 
              type="submit" 
              className="w-full p-3 bg-kintecs text-white font-bold uppercase hover:bg-gray-700 focus:outline-none cursor-pointer"
            >
              {t("sub")}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Subscribe;