import React, { useState } from 'react';
import { IoIosArrowBack} from "react-icons/io";
import { DiGoogleAnalytics } from "react-icons/di";
import { IoSettingsSharp } from "react-icons/io5";
import { MdMarkEmailRead } from "react-icons/md";
import { MdContacts } from "react-icons/md";
import { RiProjectorFill } from "react-icons/ri";
import { IoExit } from "react-icons/io5";
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { FaPhotoVideo } from "react-icons/fa";
import { MdProductionQuantityLimits } from "react-icons/md";
import { BiBookBookmark } from "react-icons/bi";

export const AdminHeader = () => {
    const navigate = useNavigate();
    const location = useLocation();
    
    const handleLogout = () => {
        localStorage.removeItem('token'); 
        navigate('/');
    };

    return (
        <div className="w-[218px] fixed top-0 left-0 h-full bg-white flex flex-col justify-between">
            <div className="w-full">
                <Link to="/">
                    <div className="text-sm text-gray-700 font-normal flex items-center space-x-1 px-3 py-2 justify-start hover:text-gray-400 cursor-pointer">
                        <IoIosArrowBack size={18}/> 
                        <h1>Back to Home</h1>
                    </div>
                </Link>
                <div className="flex justify-center items-center mt-4 cursor-pointer">
                    <img className="h-[49px] w-[106px]" src="/assets/logo.svg" alt="logo" />
                </div>
            </div>
            <ul className="mt-16 space-y-8 w-full">
                <Link to="/admin/dashboard">
                    <li className={`hover-gradient text-gray-700 font-semibold flex items-center space-x-2 px-4 py-2 cursor-pointer ${location.pathname === '/admin/dashboard' ? 'active' : ''}`}>
                        <DiGoogleAnalytics className="ml-8" size={28}/> 
                        <h1>Dashboard</h1>
                    </li>
                </Link>
                <Link to="/admin/products">
                    <li className={`hover-gradient text-gray-700 font-semibold flex items-center space-x-2 px-4 py-2 cursor-pointer ${location.pathname === '/admin/products' ? 'active' : ''}`}>
                        <MdProductionQuantityLimits className="ml-8" size={28}/> 
                        <h1>Products</h1>
                    </li>
                </Link>
                <Link to="/admin/add-project">
                    <li className={`hover-gradient text-gray-700 font-semibold flex items-center space-x-2 px-4 py-2 cursor-pointer ${location.pathname === '/admin/add-project' ? 'active' : ''}`}>
                        <RiProjectorFill className="ml-8" size={28}/> 
                        <h1>Projects</h1>
                    </li>
                </Link>
                <Link to="/admin/settings">
                    <li className={`hover-gradient text-gray-700 font-semibold flex items-center space-x-2 px-4 py-2 cursor-pointer ${location.pathname === '/admin/settings' ? 'active' : ''}`}>
                        <IoSettingsSharp className="ml-8" size={28}/> 
                        <h1>Language</h1>
                    </li>
                </Link>
                <Link to="/admin/settings">
                    <li className={`hover-gradient text-gray-700 font-semibold flex items-center space-x-2 px-4 py-2 cursor-pointer ${location.pathname === '/admin/mail' ? 'active' : ''}`}>
                        <MdMarkEmailRead className="ml-8" size={28}/> 
                        <h1>Mail</h1>
                    </li>
                </Link>
                <Link to="/admin/videos">
                    <li className={`hover-gradient text-gray-700 font-semibold flex items-center space-x-2 px-4 py-2 cursor-pointer ${location.pathname === '/admin/videos' ? 'active' : ''}`}>
                        <FaPhotoVideo className="ml-8" size={28}/> 
                        <h1>Videos</h1>
                    </li>
                </Link>
                <Link to="/admin/forms">
                    <li className={`hover-gradient text-gray-700 font-semibold flex items-center space-x-2 px-4 py-2 cursor-pointer ${location.pathname === '/admin/forms' ? 'active' : ''}`}>
                        <MdContacts className="ml-8" size={28}/>
                        <h1>Forms</h1>
                    </li>
                </Link>
                <Link to="/admin/slider">
                    <li className={`hover-gradient text-gray-700 font-semibold flex items-center space-x-2 px-4 py-2 cursor-pointer ${location.pathname === '/admin/slider' ? 'active' : ''}`}>
                        <RiProjectorFill className="ml-8" size={28}/> 
                        <h1>Sliders</h1>
                    </li>
                </Link>
                <Link to="/admin/catalogues">
                    <li className={`hover-gradient text-gray-700 font-semibold flex items-center space-x-2 px-4 py-2 cursor-pointer ${location.pathname === '/admin/catalogues' ? 'active' : ''}`}>
                        <BiBookBookmark className="ml-8" size={28}/> 
                        <h1>Catalogues</h1>
                    </li>
                </Link>
            </ul>
            <div className="flex items-center mb-4 w-full px-1">
                <div className="h-[43px] w-[43px] rounded-full bg-libart flex justify-center items-center" alt="profile">
                    <h1 className="text-center text-white font-bold text-xl">SR</h1>
                </div>
                <div className="ml-1">
                    <h1 className="text-gray-800 font-bold text-sm">Kintecs</h1>
                    <h2 className="text-gray-800 font-light text-sm">Serbia</h2>
                </div>
                <div onClick={handleLogout} className="ml-auto hover:bg-libart hover:rounded-full h-8 w-8 flex justify-center items-center hover:text-white hover-gradient2 cursor-pointer">
                    <IoExit size={24}/>
                </div>
            </div>
        </div>
    );
};

export default AdminHeader;
