import React, { useEffect, useState } from 'react'
import Subscribe from '../components/Subscribe'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import "../utils/i18n";
import axiosInstance from '../utils/axiosInstance';

const Catalogues = () => {
  const [catalogues, setCatalogues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language || 'en';

  useEffect(() => {
    const fetchCatalogues = async () => {
      try {
        setLoading(true);
        setError(null);
        const response = await axiosInstance.get('/api/catalogues');
        if (response.data.success) {
          setCatalogues(response.data.catalogues);
        } else {
          setError('Failed to fetch catalogues');
        }
      } catch (error) {
        console.error("Error fetching catalogues:", error);
        setError(error.message || 'Failed to fetch catalogues');
      } finally {
        setLoading(false);
      }
    };

    fetchCatalogues();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="mx-auto container pr-4 pl-4 md:pr-0 md:pl-0">
      <h1 className="text-kintecs font-oswald font-bold text-5xl mt-12">{t("Catalogues")}</h1>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mt-12 gap-y-20 md:gap-y0">
        {catalogues.map((catalogue) => (
          <div key={catalogue._id} className="w-full lg:w-64">
            <Link to={`/catalogues/${catalogue.slug}`}>
              <img 
                className="shadow-xl w-full h-auto" 
                src={`${axiosInstance.defaults.baseURL}${catalogue.image}`} 
                alt={catalogue.title[currentLang]} 
              />
              <h2 className="font-bold text-kintecs mt-4 text-lg">{catalogue.title[currentLang]}</h2>
              <h2 className="text-gray-500 text-sm">{catalogue.lang}</h2>
              <button className="bg-kintecs font-oswald rounded-lg py-2 px-4 text-white mt-2 hover:bg-gray-500 w-full md:w-auto">
                {t("view")}
              </button>
            </Link>
          </div>
        ))}
      </div>

      <Subscribe />
    </div>
  )
}

export default Catalogues