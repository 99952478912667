import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axiosInstance';
import { toast } from 'react-toastify';

// Slug oluşturma fonksiyonu
const createSlug = (text) => {
    return text
        .toString()
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')     // Boşlukları tire ile değiştir
        .replace(/[üÜ]/g, 'u')    // Türkçe karakterleri değiştir
        .replace(/[ıİ]/g, 'i')
        .replace(/[şŞ]/g, 's')
        .replace(/[ğĞ]/g, 'g')
        .replace(/[çÇ]/g, 'c')
        .replace(/[öÖ]/g, 'o')
        .replace(/[^a-z0-9-]/g, '') // Alfanumerik ve tire dışındaki karakterleri kaldır
        .replace(/--+/g, '-');     // Ardışık tireleri tek tireye dönüştür
};

const AdminCatalogues = () => {
    const navigate = useNavigate();
    const [catalogues, setCatalogues] = useState([]);
    const [formData, setFormData] = useState({
        title: '',
        lang: '',
    });
    const [imageFile, setImageFile] = useState(null);
    const [pdfFile, setPdfFile] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchCatalogues();
    }, []);

    const fetchCatalogues = async () => {
        try {
            const response = await axiosInstance.get('/api/catalogues');
            if (response.data.success && Array.isArray(response.data.catalogues)) {
                setCatalogues(response.data.catalogues);
            } else {
                setCatalogues([]);
                console.error('Invalid catalogues data:', response.data);
                toast.error('Error loading catalogues: Invalid data format');
            }
        } catch (error) {
            console.error('Error loading catalogues:', error);
            toast.error('Error loading catalogues');
            setCatalogues([]);
        }
    };

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleFileChange = (e, type) => {
        const file = e.target.files[0];
        if (type === 'image') {
            if (file && !file.type.startsWith('image/')) {
                toast.error('Please select an image file');
                return;
            }
            setImageFile(file);
        } else if (type === 'pdf') {
            if (file && file.type !== 'application/pdf') {
                toast.error('Please select a PDF file');
                return;
            }
            setPdfFile(file);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!imageFile || !pdfFile) {
            toast.error('Please select both image and PDF files');
            return;
        }

        setLoading(true);
        const formDataToSend = new FormData();
        // Title'ı Map olarak gönder
        const titleMap = {
            en: formData.title // Varsayılan olarak İngilizce
        };
        formDataToSend.append('title', JSON.stringify(titleMap));
        formDataToSend.append('lang', formData.lang);
        formDataToSend.append('slug', createSlug(formData.title));
        formDataToSend.append('image', imageFile);
        formDataToSend.append('pdf', pdfFile);

        // FormData içeriğini kontrol et
        console.log('FormData içeriği:');
        for (let pair of formDataToSend.entries()) {
            console.log(pair[0] + ': ' + pair[1]);
        }

        try {
            const token = localStorage.getItem('token');
            const response = await axiosInstance.post('/api/catalogues', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`
                }
            });
            console.log('Sunucu yanıtı:', response.data);
            toast.success('Katalog başarıyla eklendi');
            setFormData({
                title: '',
                lang: '',
            });
            setImageFile(null);
            setPdfFile(null);
            // Reset file inputs
            const fileInputs = document.querySelectorAll('input[type="file"]');
            fileInputs.forEach(input => input.value = '');
            fetchCatalogues();
        } catch (error) {
            console.error('Katalog eklenirken hata oluştu:', error);
            console.error('Hata detayları:', error.response?.data);
            toast.error(error.response?.data?.message || 'Katalog eklenirken bir hata oluştu');
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this catalogue?')) {
            try {
                await axiosInstance.delete(`/api/catalogues/${id}`);
                toast.success('Catalogue deleted successfully');
                fetchCatalogues();
            } catch (error) {
                console.error('Error deleting catalogue:', error);
                toast.error('Error deleting catalogue');
            }
        }
    };

    return (
        <div className="ml-[218px] p-8">
            <h1 className="text-3xl font-bold mb-8">Catalogue Management</h1>

            {/* Catalogue Add Form */}
            <div className="bg-white p-6 rounded-lg shadow-md mb-8">
                <h2 className="text-xl font-semibold mb-4">Add New Catalogue</h2>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Title</label>
                        <input
                            type="text"
                            name="title"
                            value={formData.title}
                            onChange={handleInputChange}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Language</label>
                        <input
                            type="text"
                            name="lang"
                            value={formData.lang}
                            onChange={handleInputChange}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Image</label>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => handleFileChange(e, 'image')}
                            className="mt-1 block w-full text-sm text-gray-500
                                file:mr-4 file:py-2 file:px-4
                                file:rounded-md file:border-0
                                file:text-sm file:font-semibold
                                file:bg-blue-50 file:text-blue-700
                                hover:file:bg-blue-100"
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">PDF File</label>
                        <input
                            type="file"
                            accept="application/pdf"
                            onChange={(e) => handleFileChange(e, 'pdf')}
                            className="mt-1 block w-full text-sm text-gray-500
                                file:mr-4 file:py-2 file:px-4
                                file:rounded-md file:border-0
                                file:text-sm file:font-semibold
                                file:bg-blue-50 file:text-blue-700
                                hover:file:bg-blue-100"
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 disabled:bg-blue-300"
                        disabled={loading}
                    >
                        {loading ? 'Adding...' : 'Add Catalogue'}
                    </button>
                </form>
            </div>

            {/* Catalogue List */}
            <div className="bg-white p-6 rounded-lg shadow-md">
                <h2 className="text-xl font-semibold mb-4">Current Catalogues</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {catalogues.map((catalogue) => (
                        <div key={catalogue._id} className="border rounded-lg p-4">
                            <img
                                src={`${axiosInstance.defaults.baseURL}${catalogue.image}`}
                                alt={typeof catalogue.title === 'object' ? catalogue.title.en : catalogue.title}
                                className="w-full h-48 object-cover rounded-lg mb-2"
                            />
                            <h3 className="font-semibold">
                                {typeof catalogue.title === 'object' ? catalogue.title.en : catalogue.title}
                            </h3>
                            <p className="text-sm text-gray-600">{catalogue.lang}</p>
                            <div className="mt-2 space-x-2">
                                <a
                                    href={`${axiosInstance.defaults.baseURL}${catalogue.download}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="inline-block bg-blue-500 text-white px-3 py-1 rounded-md text-sm hover:bg-blue-600"
                                >
                                    View PDF
                                </a>
                                <button
                                    onClick={() => handleDelete(catalogue._id)}
                                    className="bg-red-500 text-white px-3 py-1 rounded-md text-sm hover:bg-red-600"
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default AdminCatalogues; 