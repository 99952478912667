import React, { useEffect, useState } from 'react'
import { CiLocationOn } from "react-icons/ci";
import { IoMail } from "react-icons/io5";
import { IoCall } from "react-icons/io5";
import Country from '../components/Country';
import { useTranslation } from "react-i18next";
import "../utils/i18n";
import axios from 'axios';
import Loading from '../components/Loading';

const Contact = () => {
  const [languages, setLanguages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });
  const { t, i18n } = useTranslation();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api`); 
        setLanguages(response.data.languages); 
      } catch (error) {
        console.error("Error fetching languages:", error);
      }
    };

    fetchLanguages();
  }, []);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.post('https://api.345.com.tr/api/forms/new', formData);
      
      if (response.data.success) {
        alert(t('form_success'));
        setFormData({
          name: '',
          email: '',
          phone: '',
          message: ''
        });
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert(t('form_error'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    if (e && e.target) {
      setFormData(prev => ({
        ...prev,
        [e.target.name]: e.target.value
      }));
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="mx-auto container">
      <h1 className="text-kintecs font-oswald font-bold text-5xl mt-12 mx-auto container pr-4 pl-4 md:pr-0 md:pl-0">{t("Contact")}</h1>

      <div className="w-full border rounded-xl pt-6 pb-6 mt-12  mr-4 ml-4 md:mr-0 md:ml-0">
        <h1 className="flex items-center justify-center font-bold text-kintecs gap-x-2 text-center ">
          Official Kinetic Fabricator (EU)
        </h1>

        <h1 className="flex items-center justify-center font-bold text-kintecs gap-x-2 text-center mt-4">
          TE cooling & heating doo
        </h1>

        <h1 className="flex items-center justify-center text-kintecs gap-x-2 text-center mt-4">
        <CiLocationOn size={20} />
        Profesora Grčića 1, 21000 Novi Sad, Serbia
        </h1>

        <h1 className="flex items-center justify-center text-kintecs gap-x-2 font-oswald text-lg text-center">
         <a href="https://maps.app.goo.gl/JDmCDpSDSGjFP7sx7" target="_blank">
         {t("map")}
         </a> 
        </h1>

        <h1 className="flex items-center justify-center text-kintecs gap-x-2 text-center">
        <IoCall size={20} />
        <a href="tel:+38163589280" target="_blank">
        +38163589280
        </a>
        </h1>

        <h1 className="flex items-center justify-center text-kintecs gap-x-2 text-center">
        <IoMail size={20} />
        <a href="mailto:info@te-cooling.rs" target="_blank">
        info@te-cooling.rs
        </a>
        </h1>

      </div>

      <div className="w-full border rounded-xl pt-6 pb-6 mt-6 mr-4 ml-4 md:mr-0 md:ml-0">
        <h1 className="flex items-center justify-center font-bold text-kintecs gap-x-2 text-center ">
          International Office
        </h1>

        <h1 className="flex items-center justify-center font-bold text-kintecs gap-x-2 text-center mt-4">
          Libart - Kinetic
        </h1>

        <h1 className="flex items-center justify-center text-kintecs gap-x-2 text-center mt-4">
        <CiLocationOn size={20} />
        Vancouver BC V5R 5W2 Canada
        </h1>

        <h1 className="flex items-center justify-center text-kintecs gap-x-2 text-center">
        <IoMail size={20} />
        <a href="mailto:libartsales@libart.com" target="_blank">
        libartsales@libart.com
        </a>
        </h1>

      </div>

      {/* <div className="grid md:grid-cols-2 mt-6 gap-y-6 md:gap-x-6  mr-4 ml-4 md:mr-0 md:ml-0">
        <div className="w-full border rounded-xl pt-6 pb-6">
          <h1 className="flex items-center justify-center font-bold text-kintecs gap-x-2 text-center ">
            <img className="w-5 shadow-2xl" src="assets/flags/usa.webp" alt="USA Flag" />
            {t("amoffice")}
          </h1>

          <h1 className="flex items-center justify-center text-kintecs gap-x-2 text-center mt-4">
          <CiLocationOn size={20} />
          {t("amoffice2")}
          </h1>

          <h1 className="flex items-center justify-center text-kintecs gap-x-2 font-oswald text-lg text-center">
           <a href="https://maps.app.goo.gl/JLjSBJqQD7WGyXta7" target="_blank">
           {t("map")}
           </a> 
          </h1>

          <h1 className="flex items-center justify-center text-kintecs gap-x-2 text-center">
          <IoCall  size={20} />
          <a href="tel:+1 (800) 431 2986" target="_blank">
          +1 (800) 431 2986 (Toll Free)
          </a>
          </h1>

          <h1 className="flex items-center justify-center text-kintecs gap-x-2 text-center">
          <IoMail size={20} />
          <a href="to:info@libartusa.com" target="_blank">
          info@libartusa.com
          </a>
          </h1>
        </div>

        <div className="w-full border rounded-xl pt-6 pb-6">
          <h1 className="flex items-center justify-center font-bold text-kintecs gap-x-2 text-center pl-5 pr-5 ">
            <img className="w-5 shadow-2xl" src="assets/flags/uae.webp" alt="UAE Flag" />
            {t("midoffice")}
          </h1>

          <h1 className="flex items-center justify-center text-kintecs gap-x-2 text-center mt-4">
          <CiLocationOn size={20} />
          {t("midoffice2")}
          </h1>

          <h1 className="flex items-center justify-center text-kintecs gap-x-2 font-oswald text-lg text-center">
           <a href="https://maps.app.goo.gl/jimAs9nfKErUhGkb6" target="_blank">
           {t("map")}
           </a> 
          </h1>

          <h1 className="flex items-center justify-center text-kintecs gap-x-2 text-center">
          <IoCall  size={20} />
          <a href="tel:+971 4 252 8903" target="_blank">
          +971 4 252 8903
          </a>
          </h1>

          <h1 className="flex items-center justify-center text-kintecs gap-x-2 text-center">
          <IoMail size={20} />
          <a href="to:info@arkilibart.com" target="_blank">
          info@arkilibart.com
          </a>
          </h1>
        </div>

        <div className="w-full border rounded-xl pt-6 pb-6">
          <h1 className="flex items-center justify-center font-bold text-kintecs gap-x-2 text-center">
            <img className="w-5 shadow-2xl" src="assets/flags/india.webp" alt="India Flag" />
            {t("easoffice")}
          </h1>

          <h1 className="flex justify-center text-kintecs gap-x-2 text-center mt-4 pl-3 pr-3">
            <CiLocationOn size={20} />
            <span className="text-left">
            {t("easoffice2")}
            </span>
          </h1>

          <h1 className="flex items-center justify-center text-kintecs gap-x-2 font-oswald text-lg text-center">
            <a href="https://maps.app.goo.gl/4KhP9CCjSHNDBa6S7" target="_blank" rel="noopener noreferrer">
            {t("map")}
            </a> 
          </h1>

          <h1 className="flex items-center justify-center text-kintecs gap-x-2 text-center">
            <IoCall size={20} />
            <a href="tel:+91 86 55816511" target="_blank" rel="noopener noreferrer">
              +91 86 55816511
            </a>
          </h1>

          <h1 className="flex items-center justify-center text-kintecs gap-x-2 text-center">
            <IoCall size={20} />
            <a href="tel:+91 86 55816512" target="_blank" rel="noopener noreferrer">
              +91 86 55816512
            </a>
          </h1>

          <h1 className="flex items-center justify-center text-kintecs gap-x-2 text-center">
            <IoMail size={20} />
            <a href="mailto:sales@libart.in" target="_blank" rel="noopener noreferrer">
              sales@libart.in
            </a>
          </h1>
        </div>

        <div className="w-full border rounded-xl pt-6 pb-6">
          <h1 className="flex items-center justify-center font-bold text-kintecs gap-x-2 text-center">
            <img className="w-5 shadow-2xl" src="assets/flags/turkiye.webp" alt="Türkiye Flag" />
            {t("manoffice")}
          </h1>

          <h1 className="flex items-center justify-center text-kintecs gap-x-2 text-center mt-4">
          <CiLocationOn size={20} />
          {t("manoffice2")}
          </h1>

          <h1 className="flex items-center justify-center text-kintecs gap-x-2 font-oswald text-lg text-center">
           <a href="https://maps.app.goo.gl/ygQ4kWJJHnEDhAGX9" target="_blank">
           {t("map")}
           </a> 
          </h1>

          <h1 className="flex items-center justify-center text-kintecs gap-x-2 text-center">
          <IoCall  size={20} />
          <a href="tel:+90 212 879 0265" target="_blank">
          +90 212 879 0265
          </a>
          </h1>

          <h1 className="flex items-center justify-center text-kintecs gap-x-2 text-center">
          <IoMail size={20} />
          <a href="to:libartsales@libart.com" target="_blank">
          libartsales@libart.com
          </a>
          </h1>
        </div>
      </div> */}

      <div className="grid md:grid-cols-3 mt-12 md:mt-24 pr-4 pl-4 md:pr-0 md:pl-0">
        <div className="col-span-2 h-full">
          <h1 className="text-center md:text-end text-2xl md:text-4xl font-extrabold">{t("we")}</h1>
          <h2 className="text-center md:text-end text-xl md:text-3xl font-bold mt-6">{t("we2")}</h2>
          <img className="w-full" src="assets/map.gif" alt="World Map" />
        </div>
      </div>

      <div className="w-full p-6 bg-white md:border rounded-t-xl shadow-lg">
        <h2 className="font-bold text-lg mb-4 text-kintecs">{t("con")}</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="w-full border rounded-lg p-3 mb-4"
            placeholder={t("con2")}
            required
          />
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full border rounded-lg p-3 mb-4"
            placeholder={t("con3")}
            required
          />
          <Country 
            onChange={handleChange}
            value={formData.phone}
          />
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            className="w-full border rounded-lg p-3 mb-4"
            placeholder={t("con4")}
            rows="4"
            required
          ></textarea>
          <div className="flex items-start mb-4">
            <input 
              type="checkbox" 
              required
              className="mt-1 bg-gray-200 border-gray-300 rounded checked:text-white accent-kintecs" 
            />
            <label className="ml-2 text-sm">
              {t("con5")}
            </label>
          </div>
          <button
            type="submit"
            disabled={isLoading}
            className={`w-full p-3 ${isLoading ? 'bg-gray-400' : 'bg-kintecs hover:bg-gray-500'} text-white font-bold rounded-lg`}
          >
            {isLoading ? t('sending') : t('con6')}
          </button>
        </form>
      </div>
        
    </div>
  )
}

export default Contact