import axios from "axios";

const axiosInstance = axios.create({
    baseURL: process.env.NODE_ENV === 'development' 
        ? 'https://api.345.com.tr'
        : 'https://api.345.com.tr',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});

// Request interceptor
axiosInstance.interceptors.request.use(
    (config) => {
        // Log request details
        console.log('Request:', {
            url: config.url,
            method: config.method,
            data: config.data,
            headers: config.headers,
            baseURL: config.baseURL
        });

        // Add token if exists
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token.trim()}`;
            console.log('Token added to request:', token);
        } else {
            console.log('No token found in localStorage');
        }

        return config;
    },
    (error) => {
        console.error('Request error:', error);
        return Promise.reject(error);
    }
);

// Response interceptor
axiosInstance.interceptors.response.use(
    (response) => {
        console.log('Response:', {
            status: response.status,
            data: response.data,
            headers: response.headers
        });
        return response;
    },
    (error) => {
        console.error('Response error:', {
            message: error.message,
            response: error.response?.data,
            status: error.response?.status
        });
        return Promise.reject(error);
    }
);

export default axiosInstance;