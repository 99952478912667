import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Card, Row, Col, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axiosInstance from '../utils/axiosInstance';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const { TextArea } = Input;

const AdminProducts = () => {
  const [loadingStates, setLoadingStates] = useState({});
  const [products, setProducts] = useState([]);
  const { i18n } = useTranslation();
  const currentLang = i18n.language;
  const navigate = useNavigate();
  const [formStates, setFormStates] = useState({});

  // Ürünleri getir
  const fetchProducts = async () => {
    try {
      console.log('Fetching products...');
      const token = localStorage.getItem('token');
      if (!token) {
        message.error('Please login first');
        navigate('/admin');
        return;
      }

      const response = await axiosInstance.get('/api/products');
      console.log('Products response:', response.data);
      
      if (response.data.success) {
        setProducts(response.data.products);
        const initialFormStates = {};
        response.data.products.forEach(product => {
          initialFormStates[product._id] = {
            name_en: product.name?.en || '',
            name_sr: product.name?.sr || '',
            description_en: product.description?.en || '',
            description_sr: product.description?.sr || '',
            productType: product.productType || 'KU-FB',
            productImage: null,
            technicalImage: null
          };
        });
        setFormStates(initialFormStates);
        console.log('Form states initialized:', initialFormStates);
      } else {
        message.error('Failed to load products');
      }
    } catch (error) {
      console.error('Error fetching products:', error);
      if (error.response?.status === 401) {
        message.error('Session expired. Please login again.');
        navigate('/admin');
      } else {
        message.error('Error loading products');
      }
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      message.error('Please login first');
      navigate('/admin');
      return;
    }
    fetchProducts();
  }, [navigate]);

  const handleInputChange = (productId, field, value) => {
    setFormStates(prev => ({
      ...prev,
      [productId]: {
        ...prev[productId],
        [field]: value
      }
    }));
  };

  const handleFileChange = (productId, field, info) => {
    console.log('File change:', info);
    
    if (info.file.status === 'removed') {
      setFormStates(prev => ({
        ...prev,
        [productId]: {
          ...prev[productId],
          [field]: null
        }
      }));
      return;
    }

    // Dosya seçildiğinde
    if (info.file) {
      console.log('Selected file:', info.file);
      setFormStates(prev => ({
        ...prev,
        [productId]: {
          ...prev[productId],
          [field]: info.file.originFileObj || info.file
        }
      }));
    }
  };

  const handleSubmit = async (productId) => {
    setLoadingStates(prev => ({ ...prev, [productId]: true }));
    try {
      const values = formStates[productId];
      const formData = new FormData();
      
      const updatedName = {
        en: values.name_en,
        sr: values.name_sr
      };

      const updatedDescription = {
        en: values.description_en,
        sr: values.description_sr
      };

      formData.append('name', JSON.stringify(updatedName));
      formData.append('description', JSON.stringify(updatedDescription));
      formData.append('productType', values.productType);

      if (values.productImage) {
        formData.append('productImage', values.productImage);
      }

      if (values.technicalImage) {
        formData.append('technicalImage', values.technicalImage);
      }

      const response = await axiosInstance.put(`/api/products/${productId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.success) {
        message.success('Product updated successfully!');
        fetchProducts();
      }
    } catch (error) {
      message.error('Error updating product');
    } finally {
      setLoadingStates(prev => ({ ...prev, [productId]: false }));
    }
  };

  return (
    <div className="p-8 bg-gray-100 min-h-screen ml-[218px]">
      <div className="bg-white p-6 rounded-lg container mx-auto">
        <h1 className="text-2xl font-bold mb-6">Product Management</h1>
        <Row gutter={[16, 16]}>
          {products.map(product => {
            const isLoading = loadingStates[product._id] || false;
            const formState = formStates[product._id] || {};
            
            return (
              <Col xs={24} lg={12} key={product._id}>
                <Card title={product.name?.[currentLang] || product.name?.en || 'Unnamed Product'} className="h-full">
                  <div className="space-y-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Product Name (EN)</label>
                      <Input
                        value={formState.name_en}
                        onChange={(e) => handleInputChange(product._id, 'name_en', e.target.value)}
                        required
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700">Product Description (EN)</label>
                      <TextArea
                        rows={4}
                        value={formState.description_en}
                        onChange={(e) => handleInputChange(product._id, 'description_en', e.target.value)}
                        required
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700">Product Name (SR)</label>
                      <Input
                        value={formState.name_sr}
                        onChange={(e) => handleInputChange(product._id, 'name_sr', e.target.value)}
                        required
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700">Product Description (SR)</label>
                      <TextArea
                        rows={4}
                        value={formState.description_sr}
                        onChange={(e) => handleInputChange(product._id, 'description_sr', e.target.value)}
                        required
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700">Product Image</label>
                      <Upload
                        name="productImage"
                        listType="picture"
                        maxCount={1}
                        beforeUpload={() => false}
                        onChange={(info) => handleFileChange(product._id, 'productImage', info)}
                        onRemove={() => handleFileChange(product._id, 'productImage', { file: { status: 'removed' } })}
                      >
                        <Button icon={<UploadOutlined />}>Select Product Image</Button>
                      </Upload>
                      {product.productImage && (
                        <img 
                          src={`${axiosInstance.defaults.baseURL}${product.productImage}`}
                          alt={product.name?.en || product.name?.sr} 
                          className="mt-2 max-w-[200px]" 
                        />
                      )}
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700">Technical Drawing</label>
                      <Upload
                        name="technicalImage"
                        listType="picture"
                        maxCount={1}
                        beforeUpload={() => false}
                        onChange={(info) => handleFileChange(product._id, 'technicalImage', info)}
                        onRemove={() => handleFileChange(product._id, 'technicalImage', { file: { status: 'removed' } })}
                      >
                        <Button icon={<UploadOutlined />}>Select Technical Drawing</Button>
                      </Upload>
                      {product.technicalImage && (
                        <img 
                          src={`${axiosInstance.defaults.baseURL}${product.technicalImage}`}
                          alt={`${product.name?.en || product.name?.sr} Technical Drawing`} 
                          className="mt-2 max-w-[200px]" 
                        />
                      )}
                    </div>

                    <Button 
                      type="primary" 
                      onClick={() => handleSubmit(product._id)} 
                      loading={isLoading}
                    >
                      Update
                    </Button>
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
      </div>
    </div>
  );
};

export default AdminProducts; 